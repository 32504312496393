<template>
  <section>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style="width: 10%">
                <div class="status-change-btn-wrap">
                  <button class="btn-innerTable btn-status-change wait" type="button">수정</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <article class="page-contents-wrap">
      <div class="sub-title">
        <h3>수정 이벤트 - 번호1</h3>
      </div>
      <div class="page-content">
        <div class="item-row-container">
          <div class="item-wrap">
            <div class="item-title" style="flex: 0 0 8.5%;">Start Date</div>
            <div class="item-content">
              <date-selector :dateProps="dateProps" />
            </div>
          </div>
        </div>
        <div class="item-row-container">
          <div class="item-wrap">
            <div class="item-title" style="flex: 0 0 8.5%;">End Date</div>
            <div class="item-content">
              <date-selector :dateProps="dateProps" />
            </div>
          </div>
        </div>
        <div class="item-row-container">
          <div class="item-wrap">
            <div class="item-title" style="flex: 0 0 8.5%;">Event</div>
            <div class="item-content"><textarea></textarea></div>
          </div>
        </div>
        <div class="status-change-btn-wrap pt50">
          <button class="btn-innerTable btn-status-change approve" type="button">{{ $t('button.save') }}</button>
          <button class="btn-innerTable btn-status-change approve" type="button">{{ $t('button.close') }}</button>
        </div>
      </div>
    </article>

  </section>
</template>

<script>
import TableHead from "@/components/main/table/Head.vue";
import BtnVisibleToggle from "@/components/ui/BtnVisibleToggle";
import SearchFilter from "@/components/common/SearchFilter";
import DateSelector from "@/components/common/DateSelector";
import Pagination from "@/components/common/Pagination";
import typing from "@/components/ui/Typing";
import UiSelect from "@/components/ui/Select";
import UiCheckSet from "@/components/ui/UiCheckSet";
import UiCheck from "@/components/ui/UiCheck";
export default {
  name: "EventBonus",
  components: {
    UiSelect,
    UiCheckSet,
    UiCheck,
    BtnVisibleToggle,
    TableHead,
    SearchFilter,
    Pagination,
    DateSelector,
    typing
  },
  data() {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: [
          "index",
          "event",
          "startday",
          "endday",
          "man",
          "joinday",
          "modify"
        ]
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        count_per_page: 30,
        memId: "",
        startDate: "",
        endDate: "",
        ip:""
      },
      srchFiltersProp: {
        selectOptions: []
      },
      dateProps:{
        start:true,
        end:false
      },
      tableData: [],
      btnText: "보기",
      memList: [],
      pageInfo: {},
      totalInfo: {},
      wlContentsActive: false
    };
  },
  methods: {
    wlContentsToggle() {
      const reverseArr = this.additionalHead.reverse();
      const addPosition = this.headInfo.dataList.indexOf("wl") + 1;
      this.wlContentsActive = !this.wlContentsActive;
      for (const item of reverseArr) {
        if (this.wlContentsActive) {
          this.headInfo.dataList.splice(addPosition, 0, item);
        } else {
          this.headInfo.dataList.splice(addPosition, 1);
        }
      }
    },
    openDetail_company() {
      // console.log(id)
    },
    // async setTableData(pageNum) {
    //     if (pageNum) {
    //     this.reqData.page = pageNum;
    //   }
    //   const data = this.reqData;
    //   const listRes = await userTracking(data);
    //   const pageInfo = listRes.data.pageInfo;
    //   this.pageInfo = pageInfo;
    //   const totalInfo = listRes.data.totalInfo;
    //   this.totalInfo = totalInfo;
    //   const memList = listRes.data.list;
    //   memList.forEach(items => {
    //     for (const item in items) {
    //       if (item === "actionDate" || item === "lastLoginDt") {
    //         items[item] = replaceDateT(items[item]);
    //       }
    //       if (!isNaN(Number(items[item]))) {
    //         items[item] = numberWithCommas(items[item]);
    //       }
    //     }
    //   });
    //   this.memList = memList;
    // }
  },
  async created() {
    // this.setTableData(1);
  }
};
</script>

<style scoped>
.sub-title {
    font-size: 1em;
    font-weight: 700;
    color: var(--main);
    height: 32px;
    position: relative;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--main);
}
.sub-title > h3 {
    display: flex;
    height: 100%;
    align-items: center;
    width: 180px;
    font-size: 0.9em;
    border-start-end-radius: 10px;
    border-start-start-radius: 10px;
}
.sub-title > h3::before {
    content: '●';
    color: #18698b;
    margin-right: 7px;
}
textarea {
    width: 100%;
    min-height: 160px;
    border: 1px solid #18698b;
    resize: none;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 3px;
}
.pt50 {padding-top: 50px;}
</style>
